import React from 'react'
import Link from '../../utils/ActiveLink'
// import { handleLogout } from '../../utils/auth'
import SearchForm from './SearchForm'
import { ProgramApi } from '../dashboard/postService'

const Navbar = ({ user }) => {
    // console.log(user)
    const [menu, setMenu] = React.useState(true)
    const [programs, setPrograms] = React.useState([])
    const [loading, setLoading] = React.useState(true)


    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
        // Fetch featured programs
        const fetchPrograms = async () => {
            try {
                const response = await ProgramApi.getFeaturedPrograms();
                console.log('API Response:', response);
                // Access the data array from the response
                setPrograms(response.data || []);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching programs:', error);
                setPrograms([]);
                setLoading(false);
            }
        };



        fetchPrograms();
    }, [])

    const scrollToAboutUs = (e) => {
        e.preventDefault();
        
        if (window.location.pathname !== '/') {
            window.location.href = '/#about-us';
        } else {
            const aboutSection = document.getElementById("about-us");
            if (aboutSection) {
                aboutSection.scrollIntoView({ behavior: "smooth" });
            }
            toggleNavbar();
        }
    };
    

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        toggleNavbar(); // Close mobile menu if open
    };

    const isAdmin = user && user.role === 'admin'
    const isTeacher = user && user.role === 'teacher'

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="edemy-nav">
                    <div className="container-fluid">
                        <div className="navbar navbar-expand-lg navbar-light">

                            <Link href="/">
                                <a onClick={toggleNavbar} className="navbar-brand">
                                    <img src="/images/success_leap_logo.webp" alt="logo" />
                                </a>
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                {/* <SearchForm /> */}

                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <Link href="/" passHref>
                                            <a className="nav-link" style={{ cursor: 'pointer' }}>
                                                Home
                                            </a>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link href="/">
                                            <a onClick={e => e.preventDefault()} className="nav-link">
                                                Our Courses <i className='bx bx-chevron-down'></i>
                                            </a>
                                        </Link>

                                        <ul className="dropdown-menu">
                                            {loading ? (
                                                <li className="nav-item">
                                                    <a className="nav-link">Loading...</a>
                                                </li>
                                            ) : Array.isArray(programs) && programs.length > 0 ? (
                                                programs.map((program, index) => (
                                                    <li className="nav-item" key={program.slug || index}>
                                                        <Link href={`/program/${program.slug}`} activeClassName="active">
                                                            <a onClick={toggleNavbar} className="nav-link">
                                                                {program.title}
                                                            </a>
                                                        </Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="nav-item">
                                                    <a className="nav-link">No programs available</a>
                                                </li>
                                            )}
                                        </ul>

                                    </li>

                                    <li className="nav-item">
                                        <a onClick={scrollToAboutUs} className="nav-link" style={{ cursor: 'pointer' }}>
                                            About Us
                                        </a>
                                    </li>


                                    <li className="nav-item">
                                        <Link href="/success-stories">
                                            <a onClick={toggleNavbar} className="nav-link">
                                                Success Stories
                                            </a>
                                        </Link>
                                    </li>




                                    {(user && isTeacher || user && isAdmin) && (
                                        <li className="nav-item">
                                            <Link href="/teacher/dashboard">
                                                <a className="nav-link">
                                                    Teacher Dashboard
                                                </a>
                                            </Link>
                                        </li>
                                    )}
                                    {user && isAdmin && (
                                        <li className="nav-item">
                                            <Link href="/admin/dashboard">
                                                <a className="nav-link">
                                                    Dashboard
                                                </a>
                                            </Link>
                                        </li>
                                    )}
                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    {/* <div className="option-item">
                                        <div className="cart-btn">
                                            <Link href="/cart">
                                                <a><i className='flaticon-shopping-cart'></i> <span>3</span></a>
                                            </Link>
                                        </div>
                                    </div> */}

                                    <div className="option-item">
                                                 <Link href="/dashboard">
                                                <a className="default-btn">
                                                    <i className="flaticon-user"></i> Login/Register <span></span>
                                                </a>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;